import { useEffect, useState } from 'react';
import { SearchInput, Button, Select, Dialog } from 'evergreen-ui';
import db from './data/database.json';
import ranks from './data/ranks.json';
import birthyears from './data/birthyears.json';
import { animateScroll } from 'react-scroll';
import Profile from './components/Profile';

const pageSize = 400;

const causeList = ['İkinci Qarabağ müharibəsi', '2022, 12-13 sentyabr erməni təxribatı']

function App() {
  const [DOMArr, setDOMArr] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState(0);

  const [searchString, setSearchString] = useState('');
  const [selectedRank, setSelectedRank] = useState('');
  const [selectedBirthYears, setSelectedBirthYears] = useState('');
  const [selectedCause, setSelectedCause] = useState('');
  const [isModalShown, setIsModalShown] = useState(false);
  const [isShownToTop, setIsShownToTop] = useState(false);
  const [isMModalShown, setIsMModalShown] = useState(false);
  const [drawerInfo, setDrawerInfo] = useState({});
  const [currentData, setCurrentData] = useState(paginate(db, pageSize, 1));
  const [currentPage, setCurrentPage] = useState(1);

  const isFilter = (searchString.length !== 0 || (selectedRank.length !== 0 && selectedRank !== 'Bütün rütbələr') || (selectedBirthYears.length !== 0 && selectedBirthYears !== 'Bütün illər') || (selectedCause.length !== 0 && selectedCause !== 'Bütün hadisələr'));

  function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  useEffect(() => {
    renderData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        if (!isShownToTop) setIsShownToTop(true);
      } else {
        if (isShownToTop) setIsShownToTop(false);
      }
    })
  }, [isShownToTop]);

  useEffect(() => {
    renderData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, selectedRank, selectedBirthYears, selectedCause]);

  const nextPage = (page) => {
    if (isFilter) return;
    setCurrentPage(page);
    setCurrentData(prev => [...prev, ...paginate(db, pageSize, page)]);
  }

  const showInfo = (data) => {
    setDrawerInfo(data);
    setIsMModalShown(true);
  }

  const scrollToTop = () => {
    animateScroll.scrollTo(0);
  }

  const renderData = () => {
    const d = isFilter ? db : currentData;
    let DOM = [];
    for(let x in d) {
      const regex = new RegExp(searchString, 'gmui');
      if (searchString.length !== 0 && !d[x].name.match(regex)) continue;
      if (selectedRank.length !== 0 && selectedRank !== 'Bütün rütbələr' && d[x].title !== selectedRank) continue;
      if (selectedBirthYears.length !== 0 && selectedBirthYears !== 'Bütün illər' && d[x].date.split('.')[2] !== selectedBirthYears) continue;
      if (selectedCause.length !== 0 && selectedCause !== 'Bütün hadisələr' && (selectedCause === causeList[0] ? d[x].id.indexOf("2_") !== -1 : d[x].id.indexOf("2_") === -1 )) continue;

      DOM.push(<div className={`m-item ${d[x].id.indexOf("2_") !== -1 && "recent"}`}><img onClick={() => showInfo(d[x])} className={'m-img'} key={d[x].id} src={d[x].img} alt={d[x].name} /></div>);
    }
    setSearchResultCount(DOM.length);
    setDOMArr(DOM);
  }

  return (
    <div className={'container'}>
      <div className={'header header-nav'}>
        <a href="https://haranialdiq.com" rel="noreferrer"><span>Xəritə</span></a>
        <span onClick={() => setIsModalShown(true)}>Məlumat</span>
        <a href="https://forms.gle/c4uDH4o6UZiSm1py5" target={'_blank'} rel="noreferrer"><span>Müraciət</span></a>
      </div>
      <div className={'header'}>
        <div className={'logo-content'}>
          <a href={'https://az.wikipedia.org/wiki/Xar%C4%B1b%C3%BClb%C3%BCl_(simvol)'} rel={"noreferrer"} target={'_blank'}><img className={'logo'} src={'logo.png'} alt={'Xarıbülbül'} /></a>
          <span>İkinci Qarabağ Müharibəsi Şəhidlərinə həsr edilmişdir</span>
        </div>
      </div>
      <div className={'header header-filter'} style={{ backgroundColor: '#444444', marginBottom: 20 }}>
        <div className={'filter-area'}>
          <div className={'search'}><SearchInput placeholder="Axtar..." height={30} width={'100%'} value={searchString} onChange={e => setSearchString(e.target.value)} /></div>
          <div className={'filters'}>
            <span className={'sort-by'}>
              <Select value={selectedRank} width={'100%'} onChange={event => setSelectedRank(event.target.value)}>
                {
                  ['Bütün rütbələr', ...ranks].map(label => <option key={label} value={label}>{label}</option>)
                }
              </Select>
            </span>
            <span className={'sort-by'}>
              <Select value={selectedBirthYears} width={'100px'} onChange={event => setSelectedBirthYears(event.target.value)}>
                {
                  ['Bütün illər', ...birthyears].map(label => <option key={label} value={label}>{label}</option>)
                }
              </Select>
            </span>
            <span className={'sort-by'}>
              <Select value={selectedCause} width={'100%'} onChange={event => setSelectedCause(event.target.value)}>
                {
                  ['Bütün hadisələr', ...causeList].map(label => <option key={label} value={label}>{label}</option>)
                }
              </Select>
            </span>
          </div>
        </div>
      </div>
      {
        isFilter && (
          <div className={'search-result-label'}>
            Axtarış nəticəsi: {searchResultCount}
          </div>
        )
      }
      <div className="m-list" id={'m-list-cont'}>
        {DOMArr}
      </div>
      {
        (((db?.length - currentData?.length) !== 0) && !isFilter) && (
          <div className={'load-more-btn'}>
            <Button onClick={() => nextPage(currentPage + 1)}>Növbəti siyahı ({db?.length - currentData?.length})</Button>
          </div>
        )
      }
      <Dialog
        isShown={isMModalShown}
        title={drawerInfo.name}
        onCloseComplete={() => setIsMModalShown(false)}
        hasFooter={false}
      >
        <Profile drawerInfo={drawerInfo}/>
      </Dialog>
      <Dialog
        isShown={isModalShown}
        title="Sayt haqqında"
        onCloseComplete={() => setIsModalShown(false)}
        hasFooter={false}
      >
        <p>
          Saytın məqsədi Vətən müharibəsi  Şəhidlərinin əziz xatirəsini yaşatmaqdır.
          <br /><br />
          Müharibə vaxtı yaratdığım <a href="https://haranialdiq.com" target="_blank" rel="noreferrer">"Haranı Aldıq?"</a> vebsaytı vətəndaşları xəritə üzərindən Qarabağda hansı şəhər və kəndlərin işğaldan azad olunması barədə interaktiv şəkildə məlumatlandırırdı.
          <br /><br />
          Bu vebsaytın məqsədi isə vətəndaşlara interaktiv olaraq Şəhidlərimizi tanıtmaqdır.
          <br /><br />
          Azərbaycan Respublikası Müdafiə Nazirliyinin vebsaytında təqdim olunan və yenilənən <a href="https://mod.gov.az/az/news/veten-muharibesinde-sehid-olmus-herbi-qulluqcularin-siyahisi-34025.html" target="_blank" rel="noreferrer">İkinci Qarabağ müharibəsi</a> və <a href="https://mod.gov.az/az/news/ermenistan-silahli-quvvelerinin-toretdiyi-texribatlarin-qarsisinin-alinmasi-zamani-sehid-olan-herbi-qulluqcularin-siyah-42422.html" target="_blank" rel="noreferrer">2022-ci il sentyabrın 12-dən etibarən Ermənistan silahlı qüvvələrinin törətdiyi genişmiqyaslı təxribatları</a> mənbələrindən istinad edilib.
          <br /><br />
          Təklif və iradlarınız üçün əlaqə keçidləri:<br />
          <a href="mailto:rufat@botdelive.com" target="_blank" rel="noreferrer">E-poçt</a><br />
          <a href="https://www.linkedin.com/in/rufatmammadli/" target={'_blank'} rel="noreferrer">LinkedIn</a>
        </p>
      </Dialog>
      <div className={`scroll-to-top ${isShownToTop && 'scroll-fadeIn'}`} onClick={scrollToTop}>
        Yuxarı çıx
      </div>
    </div>
  );
}

export default App;
